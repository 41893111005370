<template>
  <div class="patient-information-form-wrapper">
    <div class="form">
      <div
        v-if="
          props.isSponsored &&
          ((props.medicalNecessity && props.medicalNecessity.length) ||
            (props.labResults && props.labResults.length) ||
            (props.patientChart && props.patientChart.length))
        "
        class="patient-information-part">
        <h5>Supporting Documents</h5>
        <hr class="bold-hr" />
        <div class="full-width-table-container">
          <table>
            <thead>
              <tr>
                <th>Statement of Medical Necessity</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Signed Statement by Physician</td>
                <td>
                  <div v-if="props.medicalNecessity && props.medicalNecessity.length">
                    <div v-for="medNecessity in props.medicalNecessity" :key="medNecessity" class="download">
                      <a :href="medNecessity" target="_blank">Download</a>
                    </div>
                  </div>
                  <div v-else>
                    <span v-if="!isEditable">Not Uploaded</span>
                    <span v-else>
                      <UploadFileComponent
                        :download-destination="`medicalNecessity`"
                        class="input-wrapper"
                        @download-link="addDownloadLink" />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="props.labResults && props.labResults.length" class="full-width-table-container">
          <table>
            <thead>
              <tr>
                <th>Lab Results</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="labResult in props.labResults" :key="labResult.label">
                <td>{{ labResult.label }}</td>
                <td>
                  <span v-if="labResult.links && labResult.links.length">
                    <div v-for="link in labResult.links" :key="link">
                      <div class="download">
                        <a :href="link" target="_blank">Download</a>
                      </div>
                    </div>
                  </span>
                  <span v-else>
                    <span v-if="!isEditable">Not Uploaded</span>
                    <span v-else>
                      <UploadFileComponent
                        :download-destination="`labResults`"
                        :download-label="labResult.label"
                        class="input-wrapper"
                        @download-link="addDownloadLink" />
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="props.patientChart && props.patientChart.length" class="full-width-table-container">
          <table>
            <thead>
              <tr>
                <th>Patient Chart Notes</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="chart in props.patientChart" :key="chart.label">
                <td>{{ chart.label }}</td>
                <td>
                  <span v-if="chart.links && chart.links.length">
                    <div v-for="link in chart.links" :key="link">
                      <div class="download">
                        <a :href="link" target="_blank">Download</a>
                      </div>
                    </div>
                  </span>
                  <span v-else>
                    <span v-if="!isEditable">Not Uploaded</span>
                    <span v-else>
                      <UploadFileComponent
                        :download-destination="`patientChart`"
                        :download-label="chart.label"
                        class="input-wrapper"
                        @download-link="addDownloadLink" />
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else :class="{ hide: !props.isEditable }">
        <SupplementalDocumentsUploadComponent
          :supplemental-docs="supplementalDocs"
          @add-free-form-supplemental-docs="addFreeFormSupplementalDocsDownloadLink" />
      </div>
      <div v-if="props.isSponsored" class="guidelines-checklist">
        <h5 v-if="props.guidelines.length">
          I want to attach the following clinical guidelines to my patient’s prior authorization
        </h5>
        <div v-for="guideline in props.guidelines" :key="guideline.label" class="checklist-item">
          <input
            :id="`${guideline.label}`"
            v-model="guidelines"
            :value="guideline"
            type="checkbox"
            :disabled="!props.isEditable" />
          <label :for="`${guideline.label}`">
            <a :href="guideline.url" target="_blank">{{ guideline.label }}</a>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import UploadFileComponent from "@/components/UploadFileComponent";
import SupplementalDocumentsUploadComponent from "@/components/SupplementalDocumentsUploadComponent";

const props = defineProps({
  labResults: {
    type: Array,
    default() {
      return [];
    },
  },
  patientChart: {
    type: Array,
    default() {
      return [];
    },
  },
  guidelines: {
    type: Array,
    default() {
      return [];
    },
  },
  medicalNecessity: {
    type: Array,
    default() {
      return [];
    },
  },
  isEditable: {
    type: Boolean,
    default() {
      return false;
    },
  },
  supplementalDocs: {
    type: Array,
    default() {
      return [];
    },
  },
  isSponsored: {
    type: Boolean,
    default() {
      return false;
    },
  },
});

const supplementalDocs = ref(props.supplementalDocs);

const emit = defineEmits(["addUploadedFile", "updateGuidelines", "add-free-form-supplemental-docs"]);

const addDownloadLink = (downloadLinkInfo) => {
  emit("addUploadedFile", downloadLinkInfo);
};

const addFreeFormSupplementalDocsDownloadLink = (downloadLinkInfo) => {
  emit("add-free-form-supplemental-docs", downloadLinkInfo);
};

const guidelines = ref(props.guidelines);

watch(guidelines, (newGuidelines) => {
  emit("updateGuidelines", newGuidelines);
});
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
