<template>
  <div class="patient-information-form-wrapper">
    <div class="form">
      <div class="patient-information-part">
        <h5>View Patient Records</h5>
        <div>
          <form @submit.prevent="searchPatientRecords">
            <div class="fields-in-row">
              <div class="input-elements-group">
                <label for="date-from">Date From</label>
                <input
                  id="date-from"
                  v-model="dateFrom"
                  type="date"
                  @keyup="(event) => sendFormByEnterClicking(event, searchPatientRecords)" />
              </div>
              <div class="input-elements-group">
                <label for="date-to">Date To</label>
                <input
                  id="date-to"
                  v-model="dateTo"
                  type="date"
                  @keyup="(event) => sendFormByEnterClicking(event, searchPatientRecords)" />
              </div>
              <div class="input-elements-group half-form-container-width">
                <label for="search-query">Filter by Keyword</label>
                <input
                  id="search-query"
                  v-model="searchQuery"
                  placeholder="eg. Diagnosis"
                  type="text"
                  @keyup="(event) => sendFormByEnterClicking(event, searchPatientRecords)" />
              </div>
            </div>
          </form>
        </div>
        <div>
          <div class="accented-table-container scrollable-table-container">
            <table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Description</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading">
                  <td>Loading...</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr v-if="!patientRecords.length && !loading">
                  <td>No records found</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr v-for="record in displayedPatientRecords" :key="record.orderId">
                  <td>{{ titleCaseString(record.category) }}</td>
                  <td>{{ record.description }}</td>
                  <td>{{ record.dateOfRecord }}</td>
                  <td>
                    <span v-if="record.downloadLink"><a class="download" :href="record.downloadLink">Download</a></span>
                    <span v-else><button class="download" @click="showDataPreview(record.details)">View</button></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="openDataPreview">
            <div class="light-background-section">
              <ul>
                <li v-for="item in dataPreview" :key="item">
                  <span name="list-item-text"> {{ item }} </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { orderInfoService } from "@/services/orderInfoService";
import { sendFormByEnterClicking, titleCaseString } from "@/utils";

const patientRecords = ref([]);
const displayedPatientRecords = ref([]);
const dateFrom = ref(null);
const dateTo = ref(null);
const searchQuery = ref("");
const openDataPreview = ref(false);
const dataPreview = ref(null);
const loading = ref(false);

const props = defineProps({
  orderKey: {
    type: String,
    required: true,
  },
});

onBeforeMount(async () => {
  patientRecords.value = await orderInfoService.fetchPatientRecords(props.orderKey);
  if (patientRecords.value.length) {
    patientRecords.value.forEach((record) => {
      // TODO to add details attribute to record we need to iterate of the record object and create a string with key: value pairs and join them with a new line
      record.details = Object.entries(record).map(([key, value]) => `${key}: ${value}`);
      record.searchableData = Object.entries(record)
        .map(([key, value]) => `${key}: ${value}`)
        .join(" ")
        .toLowerCase();
    });
  }
  displayedPatientRecords.value = patientRecords.value;
});

function showDataPreview(data) {
  dataPreview.value = data;
  openDataPreview.value = true;
}

function searchPatientRecords() {
  loading.value = true;
  openDataPreview.value = false;
  dataPreview.value = null;
  let filteredRecords = patientRecords.value.filter((record) => {
    const searchTerms = searchQuery.value.toLowerCase().split(" ");
    return searchTerms.every((term) => record.searchableData.includes(term));
  });
  if (dateFrom.value) {
    filteredRecords = filteredRecords.filter((record) => record.dateOfRecord >= dateFrom.value || !record.dateOfRecord);
  }
  if (dateTo.value) {
    filteredRecords = filteredRecords.filter((record) => record.dateOfRecord <= dateTo.value || !record.dateOfRecord);
  }
  displayedPatientRecords.value = filteredRecords;
  loading.value = false;
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
